<template>
  <v-dialog persisten content-class="dialog-rounded" v-model="showDelete" width="500">
    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{$t("delete") }}
        </prosk-title>
      </v-card-title>

      <v-card-text class="pt-0 pb-0">
        <span class="black--text">{{ $t("areYouSureDelete") }}</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" outlined>
          {{ $t("cancel") }}
        </general-button>
        <general-button
          :action="confirmDelete"
          :loading="loading"
          danger
        >
          {{ $t("delete") }}
        </general-button>
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import GeneralButton from '../buttons/GeneralButton.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import ProskTitle from '../markup/ProskTitle.vue';

export default {
  name: 'DeleteWarning',
  props: ['onClose', 'confirmDelete', 'showDelete', 'loading'],
  components: {
    DetailsCard,
    ProskTitle,
    GeneralButton
  }
};
</script>
