<template>
  <div :class="($vuetify.breakpoint.smAndDown) ? 'sm' : undefined">
    <prosk-title v-if="hasTitle">
      <slot name="title" ></slot>
    </prosk-title>
    <v-card v-if="hasCard" elevation="0" class="details-card" :class="[cardClass, {scrollable}]">
      <slot></slot>
      <v-card-text v-if="hasContent">
        <slot name="content" ></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';

export default {
  name: 'DetailsCard',
  components: {
    ProskTitle
  },
  props: {
    cardClass: {
      type: String,
      default: ''
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasTitle () {
      return !!this.$slots.title;
    },
    hasCard () {
      return !!this.$slots.default || !!this.$slots.content;
    },
    hasContent () {
      return !!this.$slots.content;
    }
  }
};
</script>
<style lang="scss" scoped>
  .details-card {
    border-radius: 32px;
    margin-bottom: 40px;
    box-shadow: 0px 3px 50px 0px #3F44A612 !important;

    &.scrollable {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;

      :deep(.v-card__text) {
        overflow: auto;
        padding: 20xp 40px;
      }
    }

    :deep(.v-card__text) {
      padding: 40px;
    }

    :deep(.v-card__title.title) {
      padding: 40px 40px 0
    }
  }
</style>
<style lang="scss" scoped>
  .sm {
    .details-card .v-card__text {
      padding: 24px 32px;
    }
  }
</style>
