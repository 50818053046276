<template>
  <component
    :is="tag"
    :class="{
      'prosk-title': true,
      'secondary--text': !noColor
    }"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'ProskTitle',
  props: {
    tag: {
      type: String,
      default: 'h3'
    },
    noColor: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
  h1.prosk-title,
  h2.prosk-title,
  h3.prosk-title,
  h4.prosk-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }
</style>
